import React from 'react';

import ajaxer from '../ajax';
import formatArticle from '../format-article';
import TrackedLink from '../display/components/TrackedLink';
import ArticleBox from '../api/components/ArticleBox';
import buildApiUrl from './utils/build-api-url';

import {
  IApiResponse,
  IApiArticle,
  IArticle,
  IGaLabel,
  IGaActionLabel,
} from '../types';

interface IFetchDataInner {
  (): Promise<IArticle[]>;
}

export interface ISeriesLatestProps {
  ga: IGaLabel;
  name: string;
  scrollTarget: HTMLElement;
  slug: string;
  headingLevel?: number;
  limit?: number;
  excludeIds?: number[];
  display?: {
    showSeriesLink?: boolean;
  };
}

function buildFetchData({
  slug,
  limit,
  excludeIds,
}: {
  slug: string;
  limit: number;
  excludeIds: number[];
}): IFetchDataInner {
  const url = buildApiUrl({ slug, limit, excludeIds });

  return async function fetchData(): Promise<IArticle[]> {
    const {
      data: { results },
    } = await ajaxer.get<IApiResponse<IApiArticle>>(url);

    return results.map((result) => formatArticle(result));
  };
}

const SeriesLatest: React.FC<ISeriesLatestProps> = ({
  ga,
  name,
  scrollTarget,
  slug,
  headingLevel = 2,
  limit = 3,
  excludeIds = [],
  display: { showSeriesLink = true } = {
    showSeriesLink: true,
  },
}) => {
  const fetchData = buildFetchData({ slug, limit, excludeIds });
  const link = `/series/${slug}/`;

  const renderHeading = ({
    action,
    label,
  }: IGaActionLabel): React.ReactNode => (
    <>
      {showSeriesLink && (
        <>
          <span className="l-display-block has-xxs-btm-marg">
            Latest from the series
          </span>{' '}
          <span className="t-links t-size-b t-case-reset">
            <TrackedLink
              action={action}
              category="read more"
              href={link}
              label={label}
            >
              {name} &raquo;
            </TrackedLink>
          </span>
        </>
      )}
    </>
  );

  return (
    <ArticleBox
      display={{ hasFullBorder: showSeriesLink }}
      fetchData={fetchData}
      ga={{ label: ga.label, action: `series - ${name}` }}
      headingLevel={headingLevel}
      renderHeading={renderHeading}
      scrollTarget={scrollTarget}
    />
  );
};

export default SeriesLatest;

import React from 'react';

interface IIconProps {
  iconName: string;
  display?: {
    color?: string;
    extraClasses?: string[];
    srHidden?: boolean;
    baseline?: boolean;
  };
}

const Icon: React.FC<IIconProps> = ({
  iconName,
  display: {
    color = '',
    extraClasses = [],
    srHidden = true,
    baseline = false,
  } = {
    color: '',
    extraClasses: [],
    srHidden: true,
    baseline: false,
  },
}) => {
  const classes = ['c-icon'];

  if (color) {
    classes.push(`has-text-${color}`);
  }
  if (baseline) {
    classes.push('c-icon--baseline');
  }

  extraClasses.forEach(cls => {
    classes.push(cls);
  });

  return (
    <span className={classes.join(' ')}>
      <svg aria-hidden={srHidden}>
        <use xlinkHref={`#${iconName}`} />
      </svg>
    </span>
  );
};

export default Icon;

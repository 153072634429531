import formatImage from './format-image';

const formatEvents = data => {
  const formatted = [];

  data.forEach(
    ({
      id,
      name,
      eventbrite_url: eventbrite,
      override_url: override,
      livestream_url: livestream,
      venue_city: city,
      starts_at: startDate,
      ends_at: endDate,
      lead_art: image,
      is_virtual: isVirtual,
    }) => {
      const obj = {
        city,
        endDate,
        id,
        isVirtual,
        name,
        primaryUrl: override || eventbrite,
        startDate,
      };

      if (image) {
        obj.image = formatImage(image);
      }

      if (livestream) {
        obj.livestreamUrl = livestream;
      }

      formatted.push(obj);
    }
  );

  return formatted;
};

export default formatEvents;

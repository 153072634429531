import React from 'react';

import TrackedLink from './TrackedLink';

import { ILinkListItem, IGaActionLabel } from '../../types';

interface IGaActionLabelMaybeCategory extends IGaActionLabel {
  category?: string;
}

interface IListProps {
  ga: IGaActionLabelMaybeCategory;
  items: ILinkListItem[];
}

const List: React.FC<IListProps> = ({
  ga: { action, label, category = 'read more' },
  items,
}) => (
  <ul>
    {items.map(({ text, id, url }, index) => (
      <li
        key={id}
        className={index !== items.length - 1 ? 'has-s-btm-marg' : ''}
      >
        <p className="has-text-gray-dark t-links-underlined-hover t-size-s t-serif">
          <TrackedLink
            action={action}
            category={category}
            href={url}
            label={label}
          >
            {text}
          </TrackedLink>
        </p>
      </li>
    ))}
  </ul>
);

export default List;

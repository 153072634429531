import formatImage from './format-image';

import { IApiArticle, IArticle, IImage } from './types';

const formatArticle = ({
  id,
  headline,
  ap_pub_date: pubDate,
  sitewide_image: sitewideImage,
  ap_updated_date: updatedDate,
  url,
}: IApiArticle): IArticle => {
  const image: IImage = formatImage(sitewideImage);

  return {
    id,
    date: pubDate,
    hed: headline,
    image,
    thumb: sitewideImage.thumbnails.lightbox,
    updatedDate: updatedDate || undefined,
    url,
  };
};

export default formatArticle;

import { useEffect, useRef, useReducer, useCallback } from 'react';

import bindScrollActions from '../../utils/scroll-actions';

enum EActionType {
  SetPending = 'SET_IS_PENDING',
  SetData = 'SET_DATA',
  SetError = 'SET_ERROR',
}

interface IState<T> {
  data: T | null;
  didError: boolean;
  isPending: boolean;
  errorMsg: string | null;
}

interface IUseFetchReturn<T> extends IState<T> {
  isEmpty: boolean;
}

type TAction<T> =
  | { type: EActionType.SetPending }
  | { type: EActionType.SetData; data: T }
  | { type: EActionType.SetError; errorMsg: string | null };

function createReducer<T>() {
  return (state: IState<T>, action: TAction<T>): IState<T> => {
    switch (action.type) {
      case EActionType.SetPending:
        return { ...state, isPending: true, didError: false, errorMsg: null };
      case EActionType.SetData:
        return {
          ...state,
          isPending: false,
          didError: false,
          data: action.data,
          errorMsg: null,
        };
      case EActionType.SetError:
        return {
          ...state,
          isPending: false,
          didError: true,
          errorMsg: action.errorMsg,
        };
      default:
        return state;
    }
  };
}

export default function useFetch<T>({
  element,
  fetchData,
  observerOptions,
}: {
  element: HTMLElement;
  fetchData: () => Promise<T>;
  observerOptions?: IntersectionObserverInit;
}): IUseFetchReturn<T> {
  const [{ data, isPending, didError, errorMsg }, dispatch] = useReducer(
    createReducer<T>(),
    {
      data: null,
      isPending: true,
      didError: false,
      errorMsg: null,
    }
  );

  const observerRef = useRef<IntersectionObserver | null>(null);
  const fetchDataRef = useRef(fetchData);
  const isMountedRef = useRef(true);

  const getData = useCallback((): void => {
    dispatch({ type: EActionType.SetPending });

    fetchDataRef
      .current()
      .then((apiData) => {
        if (isMountedRef.current) {
          dispatch({ type: EActionType.SetData, data: apiData });
        }
      })
      .catch((err: string) => {
        if (isMountedRef.current) {
          dispatch({ type: EActionType.SetError, errorMsg: err });
        }
      });
  }, []);

  useEffect(() => {
    fetchDataRef.current = fetchData;
  });

  useEffect(() => {
    return (): void => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = bindScrollActions({
        callback: getData,
        doOnce: true,
        elements: [element],
        observerOptions,
      });
    }
  });

  const isEmpty = !!(Array.isArray(data) && !data.length);

  return {
    data,
    isEmpty,
    isPending,
    didError,
    errorMsg,
  };
}

import { IApiImage, IImage, IThumb } from './types';

type TAlias = keyof IApiImage['thumbnails'];

const formatImage = ({
  thumbnails,
  description,
  credit,
}: IApiImage): IImage => {
  const thumbs: IThumb[] = [
    {
      ratio: 'normal',
      width: 3000,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 2500,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 2200,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 1550,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 1200,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 1000,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 850,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 640,
      quality: 75,
      url: '',
    },
    {
      ratio: 'normal',
      width: 375,
      quality: 75,
      url: '',
    },
  ];
  const image: IImage = { thumbs };

  thumbs.forEach(({ width, ratio, quality }, index) => {
    const key = `${width}_${ratio}_${quality}` as TAlias;
    image.thumbs[index].url = thumbnails[key];
  });

  if (description) {
    image.description = description;
  }

  if (credit) {
    image.credit = credit;
  }

  return image;
};

export default formatImage;

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import store from '../store/configure';
import select from '../utils/select';
import FeaturedSeries from './FeaturedSeries';
import { SELECTOR } from './constants';

import { IBuildFeaturedSeriesParams } from './types';

export default function buildFeaturedSeries({
  featuredCoverage,
  ga,
  slug,
}: IBuildFeaturedSeriesParams): void {
  const attachEl = select(`#${SELECTOR}`);

  if (attachEl) {
    render(
      <Provider store={store}>
        <FeaturedSeries
          featuredCoverage={featuredCoverage}
          ga={ga}
          scrollTarget={attachEl}
          slug={slug}
        />
      </Provider>,
      attachEl
    );
  }
}

import React from 'react';
import ContentLoader from 'react-content-loader';

const ListLoader: React.FC = () => (
  <ContentLoader
    viewBox="0 0 400 130"
    animate={false}
    backgroundColor="#d0d0d0"
    foregroundColor="#d0d0d0"
    style={{ maxWidth: '100%' }}
    title="Loading indicator"
  >
    <rect x="0" y="0" rx="3" ry="3" width="100%" height="10" />
    <rect x="20" y="20" rx="3" ry="3" width="90%" height="10" />
    <rect x="20" y="40" rx="3" ry="3" width="70%" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="100%" height="10" />
    <rect x="20" y="80" rx="3" ry="3" width="80%" height="10" />
    <rect x="20" y="100" rx="3" ry="3" width="20%" height="10" />
  </ContentLoader>
);

export default ListLoader;

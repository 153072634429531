import React from 'react';

interface ILinkProps {
  action: string;
  category: string;
  children: React.ReactNode;
  href: string;
  label: string;
  noTab?: boolean;
}

const TrackedLink: React.FC<ILinkProps> = ({
  action,
  category,
  children,
  href,
  label,
  noTab = false,
}) => (
  <a
    ga-on="click"
    ga-event-action={action}
    ga-event-category={category}
    ga-event-label={label}
    href={href}
    tabIndex={noTab ? -1 : undefined}
  >
    {children}
  </a>
);

export default TrackedLink;

import React from 'react';

import { LOGO } from './constants';
import Audio from '../audio/Audio';

const TribCast = ({ dataFetch: { isPending, data } }) => (
  <section className="c-tribcast has-bg-black-off has-padding">
    <header className="has-b-btm-marg">
      <h2 className="is-sr-only">TribCasts</h2>
      <figure className="c-widget-logo has-xxxs-btm-marg l-align-center-x">
        <img
          className="l-width-full l-display-block"
          src={LOGO}
          alt="TribCast logo - various types of speech bubbles with the word, 'Tribcasts'"
        />
      </figure>
      <p className="c-tribcast__tag t-links-underlined has-text-white t-size-b t-align-center">
        The Texas Tribune&#39;s free weekly political podcast. &nbsp;
        <a href="/series/tribcast">Subscribe.</a>
      </p>
    </header>
    <div
      role="presentation"
      className="js-gtm-play"
      onClick={() => {
        window.dataLayer.push({
          event: 'customTribcastPlay',
          gaAction: 'select-content',
          gaLabel: 'tribcast player',
        });
      }}
    >
      {!isPending && (
        <Audio
          settings={{ ...data[0] }}
          display={{ isFeatured: true }}
          showDownloadLink={false}
        />
      )}
    </div>
  </section>
);

export default TribCast;

import React from 'react';
import { render } from 'react-dom';

import withDataFetch from '../api-components/hoc/withDataFetch';
import TribCast from './TribCast';
import formatQueryParams from '../format-query-params';
import select from '../utils/select';
import ajaxer from '../ajax';
import formatTribCast from './utils/format-tribcast';
import { __TEST__ } from '../constants';
import {
  SELECTOR,
  TRIBCAST_PARAMS,
  TRIBCAST_FIELDS,
  TRIBCAST_API_URL,
} from './constants';

/*
  This module is exposed in the front-page bundle.
  Django template: templates/shadow_layouts/js_components/tribcast.html
*/

function buildFetcher() {
  const url = `${TRIBCAST_API_URL}?${formatQueryParams(
    TRIBCAST_PARAMS,
    TRIBCAST_FIELDS
  )}`;

  return async () => {
    const {
      data: { results },
    } = await ajaxer.get(url);

    return formatTribCast(results);
  };
}

export default function buildTribCast() {
  const targetEl = select(`#${SELECTOR}`);

  if (targetEl != null) {
    const ComponentWithFetch = withDataFetch(TribCast, {
      fetcher: buildFetcher(),
      showLoader: true,
      scroll: { element: targetEl, options: { rootMargin: '500px' } },
    });
    const ToRender = <ComponentWithFetch />;

    if (!__TEST__) render(ToRender, targetEl);
    return ToRender;
  }

  return null;
}

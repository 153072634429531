const formatTribCast = results => {
  const [result] = results;
  const { elements } = result.placeholders.content;
  const audioElements = elements.filter(({ type }) => type === 'AudioPlugin');

  if (!audioElements.length) {
    throw new Error('TribCast plugin does not exist');
  }

  return [
    {
      name: `<b>Listen now:</b> ${result.headline}`,
      artist: '&nbsp;',
      url: audioElements[0].data.url,
      cover: '',
    },
  ];
};

export default formatTribCast;

import React from 'react';

import ajaxer from '../ajax';
import useFetch from '../api/hooks/use-fetch';
import formatNewsletter from '../format-newsletter';
import AriaLoader from '../display/components/AriaLoader';
import HeadingTag from '../display/components/HeadingTag';
import TrackedLink from '../display/components/TrackedLink';
import Icon from '../display/components/Icon';
import BlockLoader from '../display/components/BlockLoader';
import ListLoader from '../display/components/ListLoader';
import SeriesLatest from '../series-latest/SeriesLatest';
import Viz from './components/Viz';
import buildApiUrl from './utils/build-api-url';

import {
  IApiResponse,
  IApiSeries,
  ISeries,
  IApiNewsletter,
  INewsletter,
  IGaLabel,
  ILinkListItem,
} from '../types';

interface IApiSeriesPlusApiNewsletter extends IApiSeries {
  // eslint-disable-next-line camelcase
  mailchimp_signup_form: IApiNewsletter;
}

interface ISeriesPlusNewsletter extends ISeries {
  newsletter: INewsletter | null;
}

interface IFetchDataInner {
  (): Promise<ISeriesPlusNewsletter[]>;
}

function buildFetchData(slug: string): IFetchDataInner {
  const url = buildApiUrl(slug);

  return async function fetchData(): Promise<ISeriesPlusNewsletter[]> {
    const {
      data: { results },
    } = await ajaxer.get<IApiResponse<IApiSeriesPlusApiNewsletter>>(url);

    return results.map((result) => {
      const { mailchimp_signup_form: mailchimpSignupForm, ...rest } = result;
      const newsletter = mailchimpSignupForm
        ? formatNewsletter(mailchimpSignupForm)
        : null;
      return {
        ...rest,
        newsletter,
      };
    });
  };
}

interface IFeaturedSeriesProps {
  featuredCoverage: ILinkListItem[];
  ga: IGaLabel;
  scrollTarget: HTMLElement;
  slug: string;
  barColor?: string;
  headingLevel?: number;
}

const Loader: React.FC<{
  headingLevel: number;
  label: string;
}> = ({ headingLevel, label }) => (
  <section aria-label={label}>
    <HeadingTag level={headingLevel} display={{ srOnly: true }}>
      {label}
    </HeadingTag>
    <AriaLoader />
    <div aria-hidden="true" className="c-featured-series__content">
      <div className="c-featured-series__viz">
        <BlockLoader />
      </div>
      <div className="c-featured-series__latest">
        <ListLoader />
        <ListLoader />
      </div>
    </div>
  </section>
);

const FeaturedSeries: React.FC<IFeaturedSeriesProps> = ({
  featuredCoverage,
  ga,
  scrollTarget,
  slug: seriesSlug,
  headingLevel = 2,
}) => {
  // set up data fetching
  const {
    data: series,
    isEmpty,
    isPending,
    didError,
  } = useFetch<ISeriesPlusNewsletter[]>({
    element: scrollTarget,
    fetchData: buildFetchData(seriesSlug),
  });

  // if error or empty result, attach nothing to DOM
  if (didError || isEmpty) {
    return null;
  }

  // show loading indicator
  if (isPending) {
    return <Loader headingLevel={headingLevel} label="Featured series" />;
  }

  // render module
  const { name: seriesName, newsletter } = (
    series as ISeriesPlusNewsletter[]
  )[0];

  let newsletterLogo =
    'https://static.texastribune.org/media/newsletters/TT-subscription-thebrief.png';
  let newsletterName = 'The Brief';
  let newsletterSlug = 'the-brief';

  if (newsletter) {
    ({
      logo: newsletterLogo,
      name: newsletterName,
      slug: newsletterSlug,
    } = newsletter);
  }

  const action = `featured series - ${seriesName}`;
  const newsletterLink = `/newsletters/${newsletterSlug}/`;
  const excludeIds = featuredCoverage.map(({ id }) => id);
  return (
    <section
      className="c-featured-series"
      aria-label={`Featured series: ${seriesName}`}
    >
      <div className="c-featured-series__border">
        <header className="c-featured-series__header">
          <HeadingTag
            display={{
              cssClasses: [
                't-align-center',
                't-size-s',
                't-links-underlined-hover',
                't-uppercase',
              ],
            }}
            level={headingLevel}
          >
            <TrackedLink
              action={action}
              category="title"
              href={`/series/${seriesSlug}`}
              label={ga.label}
            >
              <span className="is-sr-only">Featured series: </span>
              {seriesName}
            </TrackedLink>
          </HeadingTag>
        </header>
        <div className="c-featured-series__main">
          <div className="c-featured-series__gradient is-hidden-from-bp-m" />
          <div className="c-featured-series__content">
            <div className="c-featured-series__viz">
              <Viz />
              <p
                aria-hidden="true"
                className="c-featured-series__more is-hidden-from-bp-m t-size-xxs t-uppercase has-text-gray-dark"
              >
                Swipe for more{' '}
                <Icon
                  iconName="long-arrow-right"
                  display={{ baseline: true }}
                />
              </p>
            </div>
            <div className="c-featured-series__latest">
              <SeriesLatest
                display={{ showSeriesLink: false }}
                ga={ga}
                headingLevel={headingLevel + 1}
                limit={4}
                excludeIds={excludeIds}
                name={seriesName}
                scrollTarget={scrollTarget}
                slug={seriesSlug}
              />
            </div>
          </div>
          <div className="c-featured-series__btm l-pos-rel">
            <section
              className="c-featured-series__newsletter"
              aria-label={`${seriesName}: Stay informed`}
            >
              <HeadingTag
                display={{
                  cssClasses: [
                    'has-text-black',
                    'l-display-block',
                    't-uppercase',
                    't-size-xs',
                    't-lsp-b',
                  ],
                }}
                level={headingLevel}
              >
                Stay informed
              </HeadingTag>
              <Icon
                iconName="envelope"
                display={{
                  baseline: false,
                }}
              />
              <div className="has-text-black l-display-block t-size-xs t-links-underlined t-lh-m l-align-center-self">
                Sign up for{' '}
                <TrackedLink
                  action={action}
                  category="stay informed"
                  href={newsletterLink}
                  label={ga.label}
                >
                  <strong>{newsletterName}</strong>
                </TrackedLink>{' '}
                newsletter to get updates in your inbox
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedSeries;

import React from 'react';

import HeadingTag from './HeadingTag';

interface IListHeadingProps {
  children: React.ReactNode;
  level: number;
  id?: string;
}

const ListHeading: React.FC<IListHeadingProps> = ({ children, level, id }) => (
  <HeadingTag
    level={level}
    id={id}
    display={{
      cssClasses: [
        'has-s-btm-marg',
        'has-text-black',
        'l-display-block',
        't-uppercase',
        't-size-xs',
        't-lsp-b',
      ],
    }}
  >
    {children}
  </HeadingTag>
);

export default ListHeading;

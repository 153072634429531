import React from 'react';
import format from 'date-fns/format';

import { STATIC_PATH } from '../constants';

const Heading = ({ ga: { label } }) => (
  <header className="c-widget-logo l-align-center-x has-s-btm-marg">
    <a
      href="/events/"
      className="has-text-white-off"
      ga-event-category="upcoming events"
      ga-event-action="events logo"
      ga-event-label={label}
    >
      <h2 className="is-sr-only">Upcoming Texas Tribune events</h2>
      <figure>
        <img
          className="l-width-full"
          src={`${STATIC_PATH}images/upcoming-events-logo.svg`}
          alt="The Texas Tribune logo with the word 'events' added"
        />
      </figure>
    </a>
  </header>
);

const Headline = ({ url, name, ga: { label } }) => (
  <h3 className="t-serif t-size-xs has-xxxs-btm-marg t-links-underlined-hover">
    <a
      href={url}
      ga-event-category="upcoming events"
      ga-event-action="event title"
      ga-event-label={label}
    >
      {name}
    </a>
  </h3>
);

const DateHeading = ({ date }) => {
  const [month, day] = format(date, 'MMM D').split(' ');

  return (
    <div className="t-align-center t-uppercase t-lsp-m t-weight-bold">
      <div className="t-size-s">{month}</div>
      <div className="c-upcoming-events__day has-text-yellow t-size-xxl t-lh-s">
        {day.padStart(2, '0')}
      </div>
    </div>
  );
};

const ActionLink = ({ url, text, ga: { label } }) => (
  <a
    href={url}
    ga-event-category="upcoming events"
    ga-event-action={text}
    ga-event-label={label}
  >
    {text}
  </a>
);

const ListItem = ({
  item: { primaryUrl, livestreamUrl, name, city, startDate, isVirtual },
  ga,
}) => {
  return (
    <li className="c-widget-block has-b-btm-marg">
      <DateHeading date={startDate} />
      <span>
        <Headline name={name} url={primaryUrl} ga={ga} />
        <p className="t-byline t-uppercase t-size-xxs t-lsp-m t-links">
          <span className="t-byline__item">{city}</span>
          <span className="t-byline__item">
            {primaryUrl && !isVirtual && (
              <ActionLink url={primaryUrl} ga={ga} text="attend" />
            )}
            {primaryUrl && livestreamUrl && !isVirtual && (
              <span>&nbsp;|&nbsp;</span>
            )}
            {livestreamUrl && (
              <ActionLink url={livestreamUrl} ga={ga} text="watch" />
            )}
          </span>
        </p>
      </span>
    </li>
  );
};

const ViewMore = ({ ga: { label } }) => (
  <a
    href="/events/"
    className="c-button c-button--standard has-bg-yellow has-text-black-off l-align-center-children"
    ga-on="click"
    ga-event-category="upcoming events"
    ga-event-action="more events"
    ga-event-label={label}
  >
    <span className="t-size-xxs">More upcoming events</span>
  </a>
);

const UpcomingEvents = ({ ga, dataFetch: { data } }) => (
  <section className="c-upcoming-events is-dark has-xs-padding">
    <Heading ga={ga} />
    <ul className="has-b-btm-marg">
      {data.map((item) => (
        <ListItem key={item.id} item={item} ga={ga} />
      ))}
    </ul>
    <ViewMore ga={ga} />
  </section>
);

export default UpcomingEvents;

import React, { useRef, useEffect, useState } from 'react';
import { autoInitFrames } from '@newswire/frames';

import select from '../../utils/select';
import { VIZ_SELECTOR } from '../constants';

const Viz: React.FC = () => {
  const [isBuilt, setIsBuilt] = useState(false);
  const attachRef = useRef<HTMLDivElement | null>(null);

  useEffect(
    () => {
      if (!isBuilt && attachRef.current) {
        const vizEl = select(`#${VIZ_SELECTOR}`);

        if (vizEl) {
          const attachEl = attachRef.current;
          const vizMarkup = vizEl.innerHTML;

          vizEl.parentNode.removeChild(vizEl);
          attachEl.innerHTML = vizMarkup;
        }

        setIsBuilt(true);
      }
    },
    [isBuilt]
  );

  useEffect(() => {
    if (isBuilt) {
      autoInitFrames();
    }
  });

  return <div ref={attachRef} />;
};

export default Viz;

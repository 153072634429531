import React from 'react';

import getRandomNum from '../../utils/get-random-num';
import AriaLoader from '../../display/components/AriaLoader';
import ListLoader from '../../display/components/ListLoader';
import List from '../../display/components/List';
import HeadingTag from '../../display/components/HeadingTag';
import ListHeading from '../../display/components/ListHeading';
import useFetch from '../hooks/use-fetch';

import { IArticle, IGaActionLabel } from '../../types';

interface ILoaderProps {
  children: React.ReactNode;
  headingLevel: number;
}

const Loader: React.FC<ILoaderProps> = ({ children, headingLevel }) => {
  const labelId = `label-${getRandomNum(1000)}`;

  return (
    <section aria-labelledby={labelId}>
      <HeadingTag id={labelId} level={headingLevel} display={{ srOnly: true }}>
        {children}
      </HeadingTag>
      <AriaLoader />
      <div aria-hidden="true">
        <ListLoader />
        <ListLoader />
      </div>
    </section>
  );
};

interface IArticleBoxProps {
  fetchData: () => Promise<IArticle[]>;
  ga: IGaActionLabel;
  headingLevel: number;
  renderHeading: (ga: IGaActionLabel) => React.ReactNode;
  scrollTarget: HTMLElement;
  display?: {
    hasStyledBorder?: boolean;
    hasFullBorder?: boolean;
  };
}

const ArticleBox: React.FC<IArticleBoxProps> = ({
  fetchData,
  ga,
  headingLevel,
  renderHeading,
  scrollTarget,
  display: { hasStyledBorder = false, hasFullBorder = false } = {
    hasStyledBorder: false,
    hasFullBorder: false,
  },
}) => {
  const { data: articles, isEmpty, isPending, didError } = useFetch<IArticle[]>(
    {
      element: scrollTarget,
      fetchData,
    }
  );

  if (didError || isEmpty) {
    return null;
  }

  if (isPending) {
    return <Loader headingLevel={headingLevel}>{renderHeading(ga)}</Loader>;
  }

  const labelId = `label-${getRandomNum(1000)}`;
  const sectionClasses = ['has-text-gray-dark'];

  if (hasStyledBorder) {
    sectionClasses.push('c-widget');
    sectionClasses.push('has-styled-border');
  }

  if (hasFullBorder) {
    sectionClasses.push('has-border');
    sectionClasses.push('has-padding');
    sectionClasses.push('is-rounded-b');
  }

  return (
    articles && (
      <section aria-labelledby={labelId} className={sectionClasses.join(' ')}>
        <ListHeading id={labelId} level={headingLevel}>
          {renderHeading(ga)}
        </ListHeading>
        <List
          items={articles.map(article => ({ ...article, text: article.hed }))}
          ga={ga}
        />
      </section>
    )
  );
};

export default ArticleBox;

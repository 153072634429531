import buildApiUrl from '../../build-api-url';
import { SERIES_BASE, SERIES_FIELDS } from '../../constants';

import { EApiFilter } from '../../types';

const buildFeaturedSeriesApiUrl = (slug: string): string => {
  const filters = [
    {
      name: EApiFilter.Slug,
      items: [{ value: slug, include: true }],
    },
  ];

  const apiMeta = {
    url: SERIES_BASE,
    fields: [...SERIES_FIELDS, 'mailchimp_signup_form'],
    filters,
  };

  return buildApiUrl(apiMeta);
};

export default buildFeaturedSeriesApiUrl;

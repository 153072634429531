import React from 'react';
import ContentLoader from 'react-content-loader';

const BlockLoader: React.FC = () => (
  <ContentLoader
    viewBox="0 0 400 300"
    animate={false}
    backgroundColor="#d0d0d0"
    foregroundColor="#d0d0d0"
    style={{ maxWidth: '100%' }}
    title="Loading indicator"
  >
    <circle cx="30" cy="258" r="30" />
    <rect x="75" y="233" rx="4" ry="4" width="35%" height="13" />
    <rect x="75" y="260" rx="4" ry="4" width="20%" height="8" />
    <rect x="0" y="210" rx="5" ry="5" width="100%" height="10" />
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />
  </ContentLoader>
);

export default BlockLoader;

export const SELECTOR = 'upcoming-events';

export const UPCOMING_EVENTS_PARAMS = [
  { name: 'limit', items: [{ value: 3, include: true }] },
];

export const UPCOMING_EVENTS_FIELDS = [
  'id',
  'name',
  'eventbrite_url',
  'override_url',
  'livestream_url',
  'venue_city',
  'starts_at',
  'ends_at',
  'lead_art',
  'is_virtual',
];

export const UPCOMING_EVENTS_API_URL = '/api/v2/events/upcoming/';

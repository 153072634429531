/* eslint-disable camelcase */

// ==============================================================================
// DISPLAY TYPES
// ==============================================================================
export interface ILinkListItem {
  id: number;
  text: string;
  url: string;
}

// ==============================================================================
// GA TYPES
// ==============================================================================
export interface IGaLabel {
  label: string;
}

export interface IGaActionLabel extends IGaLabel {
  action: string;
}

export interface IGaAll extends IGaActionLabel {
  category: string;
}

// ==============================================================================
// GLOBAL/GENERIC TYPES
// ==============================================================================

// TODO: Replace this enum with a string union type
// After that, put all this logic in global.ts (which will eventually be window.d.ts)
export enum EPageType {
  Article = 'article',
  Generic = 'generic',
}

export interface IGlobalData {
  pageType: EPageType;
}

export interface IGenericGlobalData extends IGlobalData {
  pageType: EPageType.Generic;
}

export interface IArticleGlobalData extends IGlobalData {
  pageType: EPageType.Article;
  authors: string[];
  contentId: number;
  gutenTags: string[];
  series: string[];
}

// ==============================================================================
// JWPLAYER TYPES
// ==============================================================================

// TODO: After global.ts is window.d.ts, move these interfaces
// into that file and use them as globals in JWPlayer.tsx component
export interface IJWPlayerTrack {
  file: string;
  label: string;
}

export interface IJWPlayerOptions {
  advertising: {
    client: string;
    enablepreloading: boolean;
    tag: string;
  };
  autostart: boolean;
  file: string;
  id: string;
  image: string;
  mediaid: string;
  mute: boolean;
  tracks: IJWPlayerTrack[];
}

export interface IJWPlayer {
  setup(options: IJWPlayerOptions): this;
  remove(): void;
}

// ==============================================================================
// API TYPES
// ==============================================================================

// For Django REST Framework list responses, which are always inside a `results` array
export interface IApiResponse<T> {
  results: T[];
}

// TODO: Remove and let IApiMeta just accept a string
export enum EApiFilter {
  Id = 'id',
  Body = 'body',
  Division = 'division',
  ContainsElement = 'contains_element',
  Cycle = 'cycle',
  FrontPage = 'front_page',
  IsActive = 'is_active',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Limit = 'limit',
  Order = 'o',
  Series = 'series',
  Slug = 'slug',
}

export interface IApiMeta {
  url: string;
  fields?: string[];
  filters?: {
    name: EApiFilter;
    items: {
      include: boolean;
      value: number | string;
    }[];
  }[];
}

// ==============================================================================
// VIDEO TYPES
// ==============================================================================

// TODO: Replace with string union type
export enum EVideoType {
  Facebook = 'facebook',
  JWPlayer = 'jwplayer',
  Livestream = 'livestream',
  YouTube = 'youtube',
}

export interface IVideoAdTargets {
  contentId?: number;
  series?: string[];
  gutenTags?: string[];
}

export interface IVideo {
  id: number;
  externalId: string;
  type: EVideoType;
  adTargets?: IVideoAdTargets;
  caption?: string;
  credit?: string;
}

// ==============================================================================
// IMAGE TYPES
// ==============================================================================
export interface IApiImage {
  credit: string;
  description: string;
  thumbnails: {
    '3000_normal_75': string;
    '2500_normal_75': string;
    '2200_normal_75': string;
    '1550_normal_75': string;
    '1200_normal_75': string;
    '1000_normal_75': string;
    '850_normal_75': string;
    '640_normal_75': string;
    '375_normal_75': string;
    lightbox: string;
  };
}

export interface IThumb {
  quality: 75;
  ratio: 'normal';
  url: string;
  width: number;
}

export interface IImage {
  thumbs: IThumb[];
  credit?: string;
  description?: string;
}

// ==============================================================================
// PLUGIN/PLACEHOLDER TYPES
// ==============================================================================

// TODO: Move this enum to constants.ts
export enum EPluginType {
  Audio = 'AudioPlugin',
  Video = 'VideoPlugin',
}

export interface IApiPlugin {
  id: number;
}

export interface IApiPluginAudio extends IApiPlugin {
  data: Record<string, unknown>;
  type: 'AudioPlugin';
}

export interface IApiPluginVideo extends IApiPlugin {
  data: {
    caption: string;
    credit: string;
    external_id: string;
    type: EVideoType;
  };
  type: 'VideoPlugin';
}

export interface IPlugin {
  id: number;
  isLeadMedia: boolean;
}

export interface IPluginVideo extends IPlugin {
  video: IVideo;
}

export interface IApiPlaceholder {
  id: number;
  elements: (IApiPluginVideo | IApiPluginVideo)[];
}

// ==============================================================================
// AUTHOR TYPES
// ==============================================================================
export interface IAuthor {
  id: number;
  affiliation: string | null;
  author: string;
  slug: string;
}

// ==============================================================================
// TAG TYPES
// ==============================================================================
export interface ITag {
  id: number;
  name: string;
  slug: string;
}

// ==============================================================================
// NEWSLETTER TYPES
// ==============================================================================
export interface IApiNewsletter {
  id: number;
  description: string;
  logo: string;
  mailchimp_interest_id: string;
  name: string;
  slug: string;
}

export interface INewsletter {
  id: number;
  description: string;
  logo: string;
  name: string;
  mailchimpId: string;
  slug: string;
}

export interface PromoImg {
  src: string;
  position: string;
}

export interface INewsletterAlt {
  authors?: number[];
  promo?: boolean;
  promoImg?: PromoImg;
  series?: number[];
  showInStoryEditor?: boolean;
  sortOrder: number;
  name: string;
  slug: string;
  sublist: string;
  deliveryFrequency: string;
  description: string;
  hexColor: string;
  isAutomated: boolean;
  logo: string;
  longDescription: string;
  mailchimpInterestId: string;
}

export interface INewsletterAltPaid extends INewsletterAlt {
  checkoutUrl: string;
}

// ==============================================================================
// SERIES TYPES
// ==============================================================================
export interface IApiSeries {
  id: number;
  name: string;
  slug: string;
  summary: string;
}

export interface ISeries {
  id: number;
  name: string;
  slug: string;
  summary: string;
}

// ==============================================================================
// ARTICLE TYPES
// ==============================================================================
export interface IApiArticle {
  id: number;
  headline: string;
  pub_date: string;
  ap_pub_date: string;
  sitewide_image: IApiImage;
  updated_date: string | null;
  ap_updated_date: string | null;
  url: string;
}

export interface IArticle {
  id: number;
  date: string;
  hed: string;
  image: IImage;
  thumb: string; // temporary
  updatedDate?: string;
  url: string;
}

export interface IRichApiArticle extends IApiArticle {
  authors: IAuthor[];
  placeholders: {
    lead_media: IApiPlaceholder;
    content: IApiPlaceholder;
  };
  series: ISeries[];
  tags: ITag[];
}

export interface IRichArticle extends IArticle {
  authors: IAuthor[];
  series: ISeries[];
  tags: ITag[];
  videoPlugins: IPluginVideo[];
}

// ==============================================================================
// POLITICIAN TYPES
// ==============================================================================
export interface IApiPolitician {
  id: number;
  slug: string;
  hometown: string;
}

export interface IPolitician {
  id: number;
  slug: string;
  hometown: string;
}

// ==============================================================================
// OFFICEHOLDER TYPES (from Republic API)
// ==============================================================================

// For API filters only (?body=<EGovBodyFilter.EdBoard>)
// TODO: move to constants.ts
export enum EGovBodyFilter {
  EdBoard = 'state-board-of-education',
  Railroad = 'texas-railroad-commission',
  TxCriminalCourt = 'texas-court-of-criminal-appeals',
  TxHouse = 'texas-house',
  TxSenate = 'texas-senate',
  TxSupremeCourt = 'texas-supreme-court',
  UsHouse = 'us-house',
  State = 'state', // EdBoard + Railroad + TxCriminalCourt + TxSupremeCourt
}

// The `body` value in the response JSON
export type TGovBody =
  | 'Texas House of Representatives'
  | 'Texas Senate'
  | 'United States House of Representatives'
  | 'Texas Supreme Court'
  | 'Texas Court of Criminal Appeals'
  | 'State Board of Education'
  | 'Railroad Commission of Texas';

// This matches up with what the API's list view returns (/v1/officeholders/)
// The detail view (/v1/officeholders/<id>/) is a bit different
export interface IApiOfficeholder {
  id: string;
  body: TGovBody | null;
  division: number | null;
  family_name: string;
  given_name: string;
  nickname: string;
  party: string;
  title: string;
  identifiers: {
    scheme: string;
    identifier: string;
  }[];
}

export interface IOfficeholder {
  id: string;
  body: TGovBody | null;
  district: number | null;
  familyName: string;
  givenName: string;
  nickname: string;
  party: string;
  title: string;
  identifiers: {
    scheme: string;
    identifier: string;
  }[];
}

import buildApiUrl from '../../build-api-url';
import { ARTICLE_BASE, ARTICLE_FIELDS } from '../../constants';

import { EApiFilter, EPageType } from '../../types';

interface IFilterValues {
  limit: number;
  slug: string;
  excludeIds?: number[];
}

const buildSeriesLatestApiUrl = ({
  limit,
  slug,
  excludeIds,
}: IFilterValues): string => {
  const filters = [
    {
      name: EApiFilter.Series,
      items: [{ value: slug, include: true }],
    },
    {
      name: EApiFilter.Limit,
      items: [{ value: limit, include: true }],
    },
    {
      name: EApiFilter.Order,
      items: [{ value: '-last_touched_date', include: true }],
    },
  ];

  if (excludeIds && excludeIds.length > 0) {
    filters.push({
      name: EApiFilter.Id,
      items: excludeIds.map((id) => ({ value: id, include: false })),
    });
  }

  if (window.ttGlobal.pageType === EPageType.Article) {
    const { contentId } = window.ttGlobal;

    filters.push({
      name: EApiFilter.Id,
      items: [{ value: contentId, include: false }],
    });
  }

  const apiMeta = {
    url: ARTICLE_BASE,
    fields: ARTICLE_FIELDS,
    filters,
  };

  return buildApiUrl(apiMeta);
};

export default buildSeriesLatestApiUrl;

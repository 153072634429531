import { IApiNewsletter, INewsletter } from './types';

const formatNewsletter = ({
  mailchimp_interest_id: mailchimpId,
  ...rest
}: IApiNewsletter): INewsletter => ({
  ...rest,
  mailchimpId,
});

export default formatNewsletter;

import React from 'react';
import { render } from 'react-dom';

import withDataFetch from '../api-components/hoc/withDataFetch';
import UpcomingEvents from './UpcomingEvents';
import formatQueryParams from '../format-query-params';
import formatEvents from '../format-events';
import select from '../utils/select';
import ajaxer from '../ajax';
import { __TEST__ } from '../constants';
import {
  SELECTOR,
  UPCOMING_EVENTS_PARAMS,
  UPCOMING_EVENTS_FIELDS,
  UPCOMING_EVENTS_API_URL,
} from './constants';

/*
  This module is exposed in the front-page bundle.
  Django template: templates/shadow_layouts/js_components/upcoming_events.html
*/

function buildFetcher() {
  const url = `${UPCOMING_EVENTS_API_URL}?${formatQueryParams(
    UPCOMING_EVENTS_PARAMS,
    UPCOMING_EVENTS_FIELDS
  )}`;

  return async () => {
    const {
      data: { results },
    } = await ajaxer.get(url);

    return formatEvents(results);
  };
}

export default function buildUpcomingEvents() {
  const targetEl = select(`#${SELECTOR}`);

  if (targetEl != null) {
    const ga = { label: 'frontpage' };
    const ComponentWithFetch = withDataFetch(UpcomingEvents, {
      fetcher: buildFetcher(),
      showLoader: true,
    });
    const ToRender = <ComponentWithFetch ga={ga} />;

    if (!__TEST__) render(ToRender, targetEl);
    return ToRender;
  }

  return null;
}

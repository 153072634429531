import logo from './img/tribcast.png';

export const LOGO = logo;

export const SELECTOR = 'tribcast';

export const TRIBCAST_API_URL = '/api/v2/articles/';

export const TRIBCAST_PARAMS = [
  { name: 'limit', items: [{ value: 1, include: true }] },
  {
    name: 'series',
    items: [{ value: 'tribcast', include: true }],
  },
];

export const TRIBCAST_FIELDS = ['id', 'placeholders', 'headline', 'url'];

import React, { Component } from 'react';
import APlayer from 'aplayer';

import 'aplayer/dist/APlayer.min.css';

export default class Audio extends Component {
  constructor(props) {
    super(props);
    this.attach = React.createRef();
  }

  componentDidMount() {
    this.buildPlayer();
  }

  buildPlayer() {
    const {
      settings,
      display: { isFeatured },
    } = this.props;

    // eslint-disable-next-line no-new
    new APlayer({
      container: this.attach.current,
      audio: {
        ...settings,
        theme: isFeatured ? '#fff' : '#ffc200',
      },
    });
  }

  render() {
    const {
      display: { isFeatured },
      settings: { url },
      showDownloadLink,
    } = this.props;

    const className = `
      c-audio
      t-sans
      c-audio--${isFeatured ? 'featured' : 'standard'}
    `;

    return (
      <>
        <div ref={this.attach} className={className} data-testid="player" />
        {showDownloadLink && (
          <p className="c-audio__download t-links t-size-s">
            <a href={url}>Download audio file</a>
          </p>
        )}
      </>
    );
  }
}

Audio.defaultProps = {
  showDownloadLink: true,
  display: {
    isFeatured: false,
  },
};

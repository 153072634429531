import { IApiMeta } from './types';

/**
 * Builds an API URL from a meta object.
 *
 * @param {IApiMeta} meta - The API metadata
 * @param {string} meta.url - The base URL
 * @param {string[]} meta.fields - The fields to include
 * @param {IApiFilter[]} meta.filters - The filters to include
 *
 * @returns {string} The API URL
 */
export default function buildApiUrl(meta: IApiMeta): string {
  const { fields, filters = [], url } = meta;
  let params = fields
    ? `fields=${fields.join(',')}${filters.length ? '&' : ''}`
    : '';

  filters.forEach(({ name, items }, paramsIdx) => {
    items.forEach(({ value, include }, itemsIdx) => {
      params += `${name}${include ? '=' : '!='}${value}`;

      const shouldAddAmpersand =
        paramsIdx !== filters.length - 1 || itemsIdx !== items.length - 1;

      if (shouldAddAmpersand) {
        params += '&';
      }
    });
  });

  return `${url}?${params}`;
}
